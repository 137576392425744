@use "sass:math";

:root {
    --font-sans-2: 'ABCArizonaFlare', sans-serif;
    --font-sans: 'HKGrotesk', sans-serif;
    --font-serif: 'SchotisText', sans-serif;

    --font-size-xxxxlarge: #{math.div(72px, 16px) * 1rem};
    --font-size-xxxlarge: #{math.div(48px, 16px) * 1rem};
    --font-size-xxlarge: #{math.div(36px, 16px) * 1rem};
    --font-size-xlarge: #{math.div(32px, 16px) * 1rem};
    --font-size-large: #{math.div(28px, 16px) * 1rem};
    --font-size-medium: #{math.div(24px, 16px) * 1rem};
    --font-size-base: #{math.div(22px, 16px) * 1rem};
    --font-size-small: #{math.div(20px, 16px) * 1rem};
    --font-size-xsmall: #{math.div(18px, 16px) * 1rem};
    // --font-size-xxsmall: #{math.div(16px, 16px) * 1rem}; // AA purposes
    --font-size-xxsmall: #{math.div(18px, 16px) * 1rem};
    --font-size-xxxsmall: #{math.div(14px, 16px) * 1rem};
    --font-size-xxxxsmall: #{math.div(12px, 16px) * 1rem};

    --line-height-sans: 1.2;
    --line-height-sans-2: 1.05;
    --letter-spacing: -0.06em;

    // --min-font-size: 5px; Canged for AA
    --min-font-size: 10px;
    --max-font-size: 18px;
    --font-size: clamp(var(--min-font-size), 1.11111vw * var(--zoom), var(--max-font-size));

    font-size: var(--font-size);

    @media (max-width: $smartphone) {
        --font-size: 16px;
    }
}

@mixin font-sans-2($line-height: 0) {
    font-family: var(--font-sans-2);
    font-weight: 300;
    @if $line-height > 0 { line-height: $line-height; }
    @else { line-height: var(--line-height-sans-2);  }
}

@mixin font-sans($line-height: 0) {
    font-family: var(--font-sans);
    font-weight: normal;
    @if $line-height > 0 { line-height: $line-height; }
    @else { line-height: var(--line-height-sans);  }
}

@mixin font-sans-bold($line-height: 0) {
    font-family: var(--font-sans);
    font-weight: 700;
    @if $line-height > 0 { line-height: $line-height; }
    @else { line-height: var(--line-height-sans);  }
}

@mixin font-serif($line-height: 0) {
    font-family: var(--font-serif);
    font-weight: 200;
    @if $line-height > 0 { line-height: $line-height; }
    @else { line-height: var(--line-height-sans);  }
}
