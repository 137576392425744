@use "sass:math";

#Message {
    --bg: var(--white);
    --color: var(--black);
    --padding-v: var(--padding-s);
    --padding-h: var(--padding-l);
    --font-size: var(--font-size-xxsmall);
    --icon-size: #{math.div(50px, 16px) * 1rem};
            
    &[aria-expanded='false'] {
        visibility: hidden;
        pointer-events: none;
    }
            
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-s);
        --padding-h: var(--padding-xs);
        --font-size: var(--font-size-xxxxsmall);
        --icon-size: #{math.div(40px, 16px) * 1rem};
    }
}

#Message {
    @include z-index($z-index-windows);

    position: fixed;
    left: 0;
    bottom: 0;
    width: calc(100% - var(--space-2));

    display: flex;
    padding: var(--padding-v) var(--padding-h);
    justify-content: space-between;
    align-items: center;

    background-color: var(--bg);
    color: var(--color);

    .content {
        align-items: center;
        display: flex;
        position: relative;

        .icon {
            margin-right: var(--padding-m);
            min-width: var(--icon-size);
        }
    }

    .controls {
        display: flex;
        justify-content: flex-end;

        button:first-child { margin-right: var(--padding-m); }
    }

    p {
        @include font-serif();
        font-size: var(--font-size);
        margin: 0;
        padding-bottom: 0;

        a {
            @include basic-a();
            text-decoration: underline;
            color: inherit;
        }
    }
}

@media (min-width: $smartphone) {
    #Message {
        p {
            padding-right: var(--padding-l);
        }
    }
}

@media (max-width: $smartphone) {
    #Message {
        display: block;
        padding: var(--padding-m) var(--padding-m) var(--padding-m) var(--padding-xs);
        width: 100%;

        .content {
            width: 100%;
            margin-bottom: var(--padding-xs);

            .icon {
                margin-right: var(--padding-xs);
            }
        }

        .controls {
            padding: 0;
            display: flex;
            justify-content: flex-start;
            padding-left: calc(var(--icon-size) + var(--padding-xs));

            button:first-child {
                background-color: var(--white);
                color: var(--black);
                margin-right: var(--padding-xs);
            }
        }
    }
}
