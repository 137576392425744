.block-image-text-grid {
    --padding-v: var(--padding-xxl);
    --padding-left: var(--padding-xl);
    --padding-right: var(--padding-m);
    --bg: var(--greyLighter);
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-l);
        --padding-left: var(--padding-xs);
        --padding-right: var(--padding-xs);
    }
}

.block-image-text-grid {
    color: var(--black);
    overflow: hidden;
    width: 100vw;

    .wrapper > div:first-child{
        padding: var(--padding-v) var(--padding-right) 0 var(--padding-left);
    }

    .text-content > div {
        border-left: 1px solid var(--grey);
    }

    @media (max-width: $smartphone) {
        background: var(--bg);

        .wrapper {
            display: flex;
            flex-direction: column;
        }

        .image-1 {
            order: 1;
        }

        .content {
            order: 2;
        }

        .image-2 {
            display: none;
        }
    }
    
    @media (min-width: $smartphone) {
        position: relative;
        
        &::before {
            @include z-index(0);
            background: var(--bg);
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: calc(100vw - var(--space-1));
        }    
        
        .wrapper {
            @include z-index(1);
            display: flex;
            position: relative;

            > div:first-child {
                flex: 0 0 50%;

                .text-content {
                    height: 100%;
                }
            }

            > div:nth-child(2) {
                overflow: hidden;
            }

            > div:nth-child(n + 2) {
                flex: 0 0 25%;
            }
        }
    }
}