@use "sass:math";

.block-slider-default {
    // --width: 100%;
    --slide-gap: var(--padding-xs);
    --margin: var(--padding-xs);

    &[data-fit="horizontal"] {
        --height: auto;
        --max-height: auto;
        --width-slide: 30vw;
    }

    &[data-fit="vertical"] {
        --height: #{math.div(1200px, 16px) * 1rem};
        --max-height: 80vh;
        --width-slide: auto;
    }

    margin: -1px 0;
    padding: 0;
    user-select: none;
}

.block-slider-default__slider {
    background-color: var(--white);
    position: relative;
    width: 100%;
    padding-bottom: var(--padding-m);
    
    cursor: grab;   
 
    &:active {
        cursor: grabbing;
    }

    > .holder {
        order: 1;
        position: relative;
        height: var(--height);
        max-height: var(--max-height);
        display: flex;
        flex-wrap: nowrap;
        overflow: hidden;
        gap: var(--slide-gap);
    }
}

.block-slider-default__item {
    position: relative;
    flex: 0 0 var(--width-slide);
    width: var(--width-slide);
    height: 100%;
    overflow: hidden;

    svg {
        height: 100%;
        width: auto;
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
    }
}

.block-slider-default__controls {
    border-top: 1px solid var(--grey);
    color: var(--black);
    order: 2;
    position: relative;
    width: 100%;
    height: auto;
    padding: var(--padding-m);
    display: flex;
    justify-content: space-between;
    align-items: center;
    pointer-events: none;

    [scroll-slider-prev] .icon {
        transform: rotate(180deg);
    }
    
    button {
        display: flex;
        align-items: center;
        justify-content: space-between;

        pointer-events: all;
        @include basic-a();
        @include font-sans(1);
        background-color: transparent;
        padding: 0;
        text-transform: uppercase;

        span:first-child {
            margin-right: var(--padding-xs);
        }

        .icon {
            height: rem(15);
            width: rem(27);
        }
    }
}