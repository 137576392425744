@use "sass:math";

#Footer {
    --font-size: var(--font-size-xxsmall);
    --padding-v-bottom: var(--padding-xs);
    --padding-v-top: var(--padding-l);
    --padding-h: var(--padding-l);
    --icon-width: #{math.div(88px, 16px) * 1rem};
    
    @media (max-width: $smartphone) {
        --font-size: var(--font-size-xxxsmall);
        --padding-h: var(--padding-s);
        --padding-v-bottom: var(--padding-l);
        --icon-width: #{math.div(61px, 16px) * 1rem};
    }
}

#Footer {
    @include font-sans();
    @include z-index(2);

    background-color: var(--footer-bg);
    color: var(--white);
    font-size: var(--font-size);
    position: relative;
    
    a {
        @include basic-a();
        color: currentColor;
    }

    .top {
        padding: var(--padding-v-top) var(--padding-h);

        .icon {
            width: var(--icon-width);
        }

        .partners {
            display: flex;
            flex-wrap: wrap;

            figure {
                width: auto;
                padding-top: 0;
                height: 100%;
            }

            img {
                position: relative;
                width: auto;
                height: 100%;
            } 
        }
    }

    .bottom {
        padding: var(--padding-v-bottom) var(--padding-h);
    }

    .top,
    .bottom {
        border-top: 1px solid var(--greyLight);
    }

    .not-found & {
        .top,
        .bottom {
            border-color: var(--white);
        }
    }

    @media (max-width: $smartphone) {
        .top {
            > div {
                .address {
                    .mail {
                        margin-top: var(--padding-xs);
                    }

                    .partners {
                        display: flex;
                        margin-top: var(--padding-xs);

                        > div {
                            height: rem(58);
                            margin-right: var(--padding-xxs);
                        }
                    }
                }
    
                .rrss {
                    display: none;
                }
    
                > div {
                    display: flex;
                    flex-direction: column;
                                
                    &:not(:last-child) {
                        margin-bottom: var(--padding-l);
                    }
                }
    
                > div:not(.newsletter) {
                    *:not(:last-child) {
                        margin-bottom: var(--padding-xxs);
                    }
                }
            }
        }

        .bottom {
            > div:first-child {
                display: flex;

                div {
                    display: flex;
                    flex-direction: column;
                    
                    &:first-child {
                        margin-right: var(--padding-m);
                    }
                    
                    *:not(:last-child) {
                        margin-bottom: var(--padding-xxxs);
                    }
                }
            }
            
            > div:last-child {
                margin-top: var(--padding-s);
            }
        }
    }
    
    @media (min-width: $smartphone) {
        .top {
            > div {
                display: flex;
                margin: auto;
                max-width: var(--content-max-width);
                
                .address {
                    .mail {
                        margin-top: var(--padding-s);
                    }

                    .partners {
                        margin-top: var(--padding-xs);

                        > div {
                            height: rem(68);
                            margin-right: var(--padding-xxs);
                        }
                    }
                }
    
                > div:not(.newsletter) {
                    display: flex;
                    flex-direction: column;
                                
                    &:not(:last-child) {
                        margin-right: var(--padding-xxl);
                        margin-bottom: var(--padding-xxxs);
                    }
                }
            }
        }

        .bottom {
            align-items: center;
            display: flex;
            justify-content: space-between;

            a {
                margin-right: var(--padding-s);
            }

            .rrss {
                display: none;
            }
        }

        .newsletter {
            flex-basis: rem(550);
            margin-right: 0;
            margin-left: auto;
        }
    }
}
