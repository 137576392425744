@use "sass:math";


.not-found-module {
    --font-size-logo: #{math.div(600px, 16px) * 1rem};
    --font-size: var(--font-size-xxxlarge);
    --font-size-cta: var(--font-size-medium);
    
    @media (max-width: $smartphone) {
        --font-size-logo: #{math.div(250px, 16px) * 1rem};
        --font-size: var(--font-size-xlarge);
        --font-size-cta: var(--font-size-base);
    }
}

.not-found-module {
    align-items: center;
    background-color: var(--red);
    display: flex;
    justify-content: center;
    min-height: 100vh;
    padding: var(--header-height) var(--padding-s);

    .icon {
        @include z-index(0);
        @include font-sans-2(.78);
        display: flex;
        font-size: var(--font-size-logo);
        left: 50%;
        opacity: .2;
        position: absolute;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
    }
    
    .content {
        @include z-index(1);
        max-width: rem(850);
        position: relative;
        text-align: center;
    }
    
    h2 {
        @include font-sans-2();
        font-size: var(--font-size);
    }
    
    a {
        @include basic-a();
        @include font-serif();
        color: currentColor;
        display: inline-block;
        font-size: var(--font-size-cta);
        margin-top: var(--padding-l);
        text-decoration: underline;
    }
        
    @media (max-width: $smartphone) {
        .icon {
            flex-direction: column;
            justify-content: center;
        }
    }
}
