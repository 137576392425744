.widget-banner-logo {
    --font-size: var(--font-size-xxxlarge);
    --padding-v: var(--padding-xxxxl);
    --padding-h: var(--padding-l);
    --bg: var(--red);
    
    @media (max-width: $smartphone) {
        --font-size: var(--font-size-xlarge);
        --padding-h: var(--padding-xs);
        --padding-v: var(--padding-xxxl);
    }
}

.widget-banner-logo {
    align-items: center;
    display: flex;
    background-color: var(--bg);
    flex-direction: column;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    position: relative;
    width: 100vw;

    h2 {
        @include font-serif();
        font-size: var(--font-size);
    }

    .icon {
        height: rem(52);
        margin-bottom: var(--padding-m);
        width: rem(270);
    }

    > div {
        padding: var(--padding-v) var(--padding-h);
    }

    .overlay {
        background: linear-gradient(120deg, transparent 15%, var(--bg) 35%);
        height: 100%;
        right: 0;
        position: absolute;
        top: 0;
        width: 180%;
        transform: translateX(0%);
    }

    @media (max-width: $smartphone) {
        .overlay {
            width: 100%;
        }
    }
}
