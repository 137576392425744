.block-image-full-text {
    --padding-v: var(--padding-xxl);
    --padding-left: var(--padding-xl);
    --padding-right: var(--padding-l);
    --bg: var(--greyDark);

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-l);
        --padding-left: var(--padding-xs);
        --padding-right: var(--padding-xs);
    }
}

.block-image-full-text {
    color: var(--black);
    position: relative;

    .wrapper > div:last-child {
        padding: var(--padding-v) var(--padding-right) 0 var(--padding-left);
    }

    > .icon {
        @include z-index(0);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);

        svg {
            path {
                fill: transparent;
            }
        }
    }

    .text-content {
        color: var(--white);

        // .label,
        // .number {
        //     color: var(--greyLighter);
        // }

        > div {
            border-left: 1px solid var(--greyLighter);
        }
    }

    @media (max-width: $smartphone) {
        .wrapper > div:first-child {
            padding: 0 var(--padding-right) 0 var(--padding-left);
           
            .text-content > div {
                padding-bottom: var(--padding-v);
            }
        }

        > .icon {
            height: 100%;
            width: auto;
        }

        .text {
            background: var(--bg);
        }
    }
    
    @media (min-width: $smartphone) {
        display: flex;
        background: var(--bg);
        margin-left: var(--gap);
        min-height: rem(700);
        overflow: hidden;
        
        .wrapper {
            @include z-index(1);
            display: flex;
            position: relative;
            width: 100%;
            
            > div {
                flex: 0 0 50%;
                
                .text-content {
                    height: 100%;
                }
            }
        }

        .media-holder {
            height: 100%;
            padding-top: 0;

            img,
            video {
                object-fit: cover;
            }
        }
        
        > .icon {
            height: auto;
            width: 100%;
        }
    }
}