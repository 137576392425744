.block-billboard-home {
    --font-size-text: var(--font-size-xlarge);
    --font-size-title: var(--font-size-xxxxlarge);
    --font-size-subtitle: var(--font-size-xxlarge);
    --font-size-label: var(--font-size-base);
    // --padding-top: var(--padding-xxxxl);
    --padding-top: var(--padding-xl);
    --padding-bottom: var(--padding-xl);
    --padding-h: var(--padding-l);
    
    @media (max-width: $smartphone) {
        --font-size-text: var(--font-size-small);
        --font-size-title: var(--font-size-xlarge);
        --font-size-subtitle: var(--font-size-xxsmall);
        --padding-top: var(--padding-xxxl);
        --padding-bottom: 0;
        --padding-h: var(--padding-xs);
    }
}

.block-billboard-home {
    @include font-serif();
    
    > div {
        align-items: center;
        color: var(--white);
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: var(--padding-top) var(--padding-h) var(--padding-bottom);
    }

    .wrapper {
        width: 100%;
    }

    .title {
        .subtitle {
            @include font-serif(1.15);
            @include z-index($z-index-wrap);
            position: relative;
            font-size: var(--font-size-subtitle);
            margin: 0 auto var(--padding-xs);
            text-align: center;
        }
        
        > div {
            margin: 0 auto;
        }
        
        h1 {
            @include font-sans-2(1.15);
            @include z-index($z-index-wrap);
            position: relative;
            font-size: var(--font-size-title);
            margin: 0 auto var(--padding-xl);
            text-align: center;
        }
    }

    .overlay {
        @include z-index($z-index-3D);
        background-color: var(--black);
        height: 100%;
        left: 0;
        // opacity: .25;
        opacity: .4;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .media-holder {
        @include z-index($z-index-bg);
        height: 100%;
        left: 0;
        padding: 0;
        position: absolute;
        top: 0;
        width: 100%;

        img,
        video {
            object-fit: cover;
        }
    }

    .text-content {
        .text {
            @include font-serif();
            font-size: var(--font-size-text);
        }

        > div {
            border-left: 1px solid var(--greyLightest);
        }

        .label,
        .number {
            color: var(--white);
        }
    }

    @media (max-width: $smartphone) {
        .title p {
            padding: 0 var(--padding-s);
        }
    }

    @media (min-width: $smartphone) {
        > div {
            min-height: 100vh;

            .wrapper {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                justify-content: space-between;
            }

            .text-content > div {
                padding-bottom: var(--padding-l);
            }
        }

        .media-holder,
        .overlay {
            height: 100vh;
        }

        & + section {
            margin-top: calc(-1 * var(--padding-xl));
        }

        .title {
            align-items: center;
            display: flex;
            flex-grow: 1;

            h1 {
                margin: 0 auto;
                max-width: rem(1180);
            }
        }

        .title .subtitle {
            margin: 0 auto var(--padding-m);
        }
    }
}