.product-content {
    --font-size: var(--font-size-medium);
    --font-size-title: var(--font-size-xxxlarge);
    --font-size-feature: var(--font-size-xxsmall);
    --padding-v: var(--padding-xl);
    --padding-left: var(--padding-m);
    --padding-right: var(--padding-l);
    
    @media (max-width: $smartphone) {
        --font-size: var(--font-size-xsmall);
        --font-size-title: var(--font-size-large);
        --padding-v: var(--padding-l);
        --padding-left: var(--padding-m);
        --padding-right: var(--padding-l);
    }
}

.product-content {
    @include z-index(10);
    background-color: var(--greyDark);
    position: relative;
    max-width: rem(720);
    padding: var(--padding-v) var(--padding-right) var(--padding-v) var(--padding-left);
    width: calc(100% - var(--padding-xs));
    
    h1 {
        @include font-sans-2(1.15);
        margin-bottom: var(--padding-m);
        font-size: var(--font-size-title);
    }
    
    .description {
        @include font-serif();
        font-size: var(--font-size);
    }
    
    .features {
        @include font-sans();
        display: flex;
        flex-wrap: wrap;
        font-size: var(--font-size-feature);
        margin-top: var(--padding-l);

        > div {
            flex: 0 0 50%;
            margin-bottom: var(--padding-l);
            max-width: 50%;
            padding-right: var(--padding-l);
        }
        
        .label {
            text-transform: uppercase;
            margin-bottom: var(--padding-xxs);
        }
    }
    
    @media (max-width: $smartphone) {
        & + .section {
            @include z-index(1);
            margin-top: calc(-1 * var(--padding-xl));
            padding-top: var(--padding-xl);
        }

        .links {
            a { width: 100%; }

            > *:first-child {
                margin-bottom: var(--padding-xs);
            }
        }
    }
    
    @media (min-width: $smartphone) {
        .features {
            margin-top: var(--padding-xl);
    
            > div {
                padding-right: var(--padding-xxxl);
            }
    
            .label {
                margin-bottom: var(--padding-xs);
            }
        }

        .links {
            display: flex;

            > *:first-child {
                margin-right: var(--padding-xs);
            }
        }

        & + .section {
            @include z-index(1);
            margin-top: calc(-1 * var(--padding-xxxxl));
            padding-top: var(--padding-xxxxl);
        }
    }
}