.text-content {
    --font-size: var(--font-size-base);
    // --font-size-label: var(--font-size-base); // Change for AA
    --font-size-label: var(--font-size-xlarge);
    --font-size-title: var(--font-size-xxxlarge);
    
    @media (max-width: $smartphone) {
        --font-size: var(--font-size-xsmall);
        --font-size-label: var(--font-size-xxxxsmall);
        --font-size-title: var(--font-size-large);
    }
}

.text-content {
    @include z-index($z-index-wrap);
    @include font-sans();
    
    font-size: var(--font-size);
    position: relative;
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
    text-align: left;
    max-width: rem(850);

    > div {
        height: 100%;
        padding-left: var(--padding-xs);
    }

    h2 {
        @include font-sans-2();
        font-size: var(--font-size-title);
        margin-bottom: var(--padding-m);
    }

    a {
        color: currentColor;
        color: var(--color);
        text-decoration: none;
    }

    .text {
        p:not(:last-child) {
            margin-bottom: var(--padding-s);
        }
    }

    // .label,
    // .number {
    //     color: var(--grey);
    // }

    .label {
        @include font-sans();
        font-size: var(--font-size-label);
        margin-bottom: var(--padding-s);
    }

    .number {
        @include font-sans();
        padding-right: var(--padding-xs);
        font-size: var(--font-size-label);
        overflow: hidden;
        align-items: center;
        display: flex;
        justify-content: flex-end;
        min-width: rem(30);
        text-align: right;

        .letter {
            height: var(--font-size-label);
            display: block;
            overflow: hidden;
    
            span {
                display: block;
                transition: transform .4s var(--ease-in-out-cubic);
            }
            
            @for $i from 1 through 10 {
                &:nth-child(#{$i}) span {
                    transition-delay: #{$i * .05}s;
                }
            }
        }
    }

    .link {
        margin-top: var(--padding-m);
    }


    @media (max-width: $smartphone) {
        > div {
            padding-bottom: var(--padding-s);
        }
    }

    @media (min-width: $smartphone) {
        > div {
            padding-bottom: var(--padding-xxl);
            padding-left: var(--padding-m);
            max-width: rem(560);
        }
        
        .number {
            padding-right: var(--padding-m);
            min-width: rem(65);
        }
        
        .text {
            p {
                margin-bottom: var(--padding-m);
            }
        }
    }
}