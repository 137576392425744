@use "sass:math";
.block-catalogue {
    --font-size: var(--font-size-medium);
    --font-size-title: var(--font-size-xxxlarge);
    --padding-top: var(--padding-l);
    --padding-bottom: var(--padding-xl);
    --padding-h: var(--padding-l);
    --icon-width: #{math.div(115px, 16px) * 1rem};
    --logo-width: #{math.div(88px, 16px) * 1rem};

    @media (max-width: $smartphone) {
        --font-size: var(--font-size-xsmall);
        --font-size-title: var(--font-size-large);
        --padding-top: var(--padding-m);
        --padding-bottom: var(--padding-xl);
        --padding-h: var(--padding-m);
        --logo-width: #{math.div(61px, 16px) * 1rem};
    }
}

.block-catalogue {
    color: var(--color);
    background-color: var(--bg);
    overflow: hidden;

    .title {
        @include font-sans-2();
        font-size: var(--font-size-title);
        margin: 0 0 var(--padding-s);
    }

    .text {
        @include font-sans();
        font-size: var(--font-size);
    }

    .image {
        @include z-index(0);
    }

    .wrapper {
        @include z-index(1);
        background-color: var(--red);
        padding: var(--padding-top) var(--padding-h) var(--padding-bottom);
        position: relative;
        
        svg {
            height: auto;
            width: 100%;
        }
        
        > .icon {
            flex: 0 0 var(--icon-width);
        }

        .logo {
            margin-bottom: var(--padding-s);
            width: var(--logo-width);
        }
    }
    
    @media (max-width: $smartphone) {
        .wrapper > .icon {
            display: none;
        }

        .title {
            margin: 0 0 var(--padding-m);
        }

        .text {
            margin: 0 0 var(--padding-l);
            
            p:not(:last-child) {
                margin: 0 0 var(--padding-s);
            }
        }
    }
    
    @media (min-width: $smartphone) {
        min-height: 100vh;
        
        .title {
            margin: 0 0 var(--padding-xl);
        }
        
        .text {
            margin: 0 0 var(--padding-xxxxl);
            
            p:not(:last-child) {
                margin: 0 0 var(--padding-m);
            }
        }
        
        .image {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;

            .media-holder {
                height: 100%;
                padding-top: 0;

                img {
                    object-fit: cover;
                }
            }
        }
        
        .wrapper {
            display: flex;
            margin-left: var(--padding-l);
            width: rem(710);
        }

        .content {
            padding-right: var(--padding-l);
        }

        .logo {
            margin-bottom: var(--padding-l);
        }
        
        // .wrapper {
            //     display: flex;
            //     gap: var(--padding-xl);
            
            //     > div {
                //         flex: 0 0 calc(50% - var(--padding-xl) / 2);
                //     }
                
                //     .wrapper {
                    //         align-items: center;
        //         display: flex;
        //         flex-direction: column;
        //         justify-content: center;
        //     }
        // }
    }
}
