@use "sass:math";

#Header {
    --font-size: var(--font-size-small);
    --color: var(--white);
    --bg: transparent;
    --padding-v: var(--padding-m);
    --padding-h: var(--padding-l);
    
    @media (max-width: $smartphone) {
        --padding-h: var(--padding-s);
    }
}

#Header {
    @include z-index($z-index-header);
    position: fixed;
    width: 100%;
    height: var(--header-height);
    right: 0;
    top: 0;
    padding: var(--padding-v) var(--padding-h);

    display: flex;
    justify-content: space-between;
    align-items: center;

    color: var(--color);
    background-color: var(--bg);

    transform: translate3d(0, var(--y-header), 10px);
    transition: background .2s ease-out;

    &.--with-bg {
        background-color: var(--black);
    }

    .toggle-menu {
        @include z-index($z-index-wrap);

        background-color: transparent;
        padding: 0;
        position: relative;
        transition: all .3s ease-in-out;
        height: rem(32);
        width: rem(32);
        
        .icon {
            @include z-index($z-index-3D);
            height: 100%;
            position: relative;
            width: 100%;
            transition: transform .2s ease-in-out, border .3s ease-in-out .2s;
            
            svg path {
                fill: var(--white);
                transition: fill .3s ease-in-out .2s;
            }
        }
        
        &.--open {
            color: var(--black);
            transform: rotateZ(135deg) translateZ(#{$z-index-wrap}px);

            &::before {
                border-color: var(--black);
            }

            svg path {
                fill: var(--black);
            }
        }

        &::before {
            @include z-index($z-index-bg);
            border-radius: 50%;
            border: 1px solid var(--white);
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            transform: scale3d(0, 0, 1);
            transition: transform .2s ease-in-out, border .3s ease-in-out .2s;
            top: 0;
            width: 100%;
        }
        
        @include isCursor() {
            &:hover {
                .icon {
                    transform: scale3d(.5, .5, 1);
                }

                &::before {
                    transform: scale3d(1, 1, 1);
                }
            }
        }
    }

    .logo .icon {
        width: var(--logo-width);
    }

    a {
        @include basic-a();
        @include font-serif();
        color: inherit;
        font-size: var(--font-size);
        overflow: hidden;

        &[aria-current="page"] {
            font-weight: bold;
            pointer-events: none;
        }

        .letter {
            display: block;
            height: calc(var(--font-size) * var(--line-height-sans));
            overflow: hidden;

            span {
                display: block;
                height: 100%;
                transition: transform .4s var(--ease-in-out-cubic);
            }
            
            @for $i from 1 through 10 {
                &:nth-child(#{$i}) span {
                    transition-delay: #{$i * .05}s;
                }
            }
        }

        @include isCursor() {
            &:hover {
                .letter span {
                    transform: translateY(-100%);
                }
            }
        }
    }

    > .nav > a,
    .toggle-menu {
        margin-left: var(--padding-l);
    }
    
    > .nav {
        display: flex;
        align-items: center;

        > a {
            display: flex;
            align-items: center;
        }

        > .langs {
            height: 100%;
            display: flex;
            align-items: center;
            margin-left: var(--padding-l);

            > .link-lang {
                padding: 0 .25em;

                &:not(:first-child) {
                    border-left: 1px solid;
                }

                &.is-active {
                    font-weight: bold;
                    pointer-events: none;
                }
            }
        }
    }

    @media (max-width: $smartphone) {
        > .nav > a {
            display: none;
        }
        > .nav > .langs {
            display: none;
        }
    }
}
