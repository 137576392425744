.block-image-text-left-right {
    --padding-v: var(--padding-xxl);
    --padding-left: var(--padding-xl);
    --padding-right: var(--padding-l);

    &:not(.--line),
    &.--sm-image {
        --padding-right: var(--padding-left);
    }
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-l);
        --padding-left: var(--padding-xs);
        --padding-right: var(--padding-xs);
    }
}

.block-image-text-left-right {
    background: var(--bg);
    color: var(--color);
    margin: -1px 0;

    .wrapper > div:last-child {
        flex-grow: 1;
        padding: var(--padding-v) var(--padding-right) 0 var(--padding-left);
    }

    .image {
        max-width: rem(580);
        overflow: hidden;

        > div {
            display: flex;
            align-items: center;
            height: 100%;
        }
    }

    .mask {
        height: rem(360);
        // clip-path: ellipse(38% 44% at 50% 50%);
        // clip-path: polygon(193.950% 4.670%, 3.610% 230.320%, 63.430% 411.770%, 193.950% 456.680%, 324.470% 411.770%, 384.290% 230.320%, 193.950% 4.670%);
        width: rem(345);
        position: relative;
        overflow: hidden;

        .icon {
            @include z-index(1);
            position: absolute;
            z-index: 10;
            width: 120%;
            height: 120%;
            display: block;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -50%, 1px);

            path {
                fill: var(--bg);
            }
        }
    }

    &.--line .text-content > div {
        border-left: 1px solid var(--grey);
    }

    &:not(.--line) {
        .text-content > div {
            align-items: center;
            display: flex;
            padding-left: 0;
        }
    }

    @media (max-width: $smartphone) {
        .wrapper {
            display: flex;
            flex-direction: column;

            > .content .text-content > div {
                padding-bottom: var(--padding-v);
            }
            
            > .mask + .content .text-content > div {
                padding-bottom: 0;
            }

            .image { order: 1; }
            .content { order: 2; }
            .mask { order: 3; }

            .mask {
                margin: var(--padding-s) auto;               
            }
        }
    }

    @media (min-width: $smartphone) {
        padding-left: var(--padding-xl);
        
        &.--masked .wrapper {
            align-items: center;
        }

        .wrapper {
            @include z-index(1);
            display: flex;
            max-width: var(--content-max-width);
            margin: auto;
            position: relative;

            > div {
                flex: 0 0 50%;

                .text-content {
                    height: 100%;
                }
            }

            > .mask {
                height: rem(605);
                flex: 0 0 rem(580);
                margin: var(--padding-s) auto;    
                
                [data-scroll-displace] {
                    height: 120%;
                    width: 100%;
                }
            }
        }

        &.--sm-image {
            padding-left: calc(var(--padding-left) + var(--padding-xl));
            
            .image {
                border-left: 1px solid var(--grey);
                max-width: none;
                
                > div {
                    margin: auto;
                    max-width: rem(350);
                }
            }

             .wrapper > div:last-child {
                flex-grow: 1;
                padding-top: 0;
                padding-left: 0;

                .text-content > div { padding-top: var(--padding-v); }
            }
        }

        &.--right {
            .wrapper {
                flex-direction: row-reverse;
            }
        }

        .image {
            margin-bottom: var(--padding-xl);
        }
        
        &.--line {
            .image {
                margin-bottom: 0;
                padding-bottom: var(--padding-xl);
            }
        }

        &:not(.--line) + .block-image-centered {
            margin-top: calc(-1 * var(--padding-xl));
        }
    }
}