#Catalogue {
    --font-size: var(--font-size-xxsmall);
    --padding-top: var(--padding-xxxxl);
    --padding-bottom: var(--padding-xxl);
    --padding-h: var(--padding-xl);
    
    @media (max-width: $smartphone) {
        --font-size: var(--font-size-xxxsmall);
        --padding-top: var(--padding-xxxl);
        --padding-bottom: var(--padding-xl);
        --padding-h: var(--padding-m);
    }
}

#Catalogue {
    .overlay {
        @include z-index($z-index-catalogue);
        background-color: transparent;
        cursor: initial;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
    }
    
    .content {
        @include z-index($z-index-catalogue);
        background: var(--black);
        color: var(--white);
        font-size: var(--font-size);
        height: 100vh;
        left: 0;
        max-width: rem(715);
        position: fixed;
        top: 0;
        transform: translate3d(-100%, 0, 0);
        width: 100%;
    }
    
    ul {
        min-height: 100%;
        padding: var(--padding-top) var(--padding-h) var(--padding-bottom);
    }

    li {
        border-bottom: 1px solid var(--greyLight);
        max-width: rem(415);
    }

    .link {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: var(--padding-xs) 0;
        
        .letters-wrapper {
            text-align: left;
            padding-right: var(--padding-xxs);
        }

        .icon-wrapper {
            margin-right: 0;
            min-width: rem(16);
        }
    }

    @include isCursor() {
        .link {  
            transition: .2s ease-in-out;
        }

        .link:hover {
            background-color: var(--white);
            color: var(--black);
            padding-left: var(--padding-xxs);
            padding-right: var(--padding-xxs);
        }
    }

    .toggle {
        @include z-index(1);
        background-color: transparent;
        height: rem(30);
        padding: 0;
        position: absolute;
        right: var(--padding-m);
        top: var(--padding-m);
        width: rem(30);
        transform: rotate(45deg);
    }

    @media (min-width: $smartphone) {
        .link {
            padding: var(--padding-s) 0;
        }

        .toggle {
            right: var(--padding-l);
            top: var(--padding-l);
        }
    }
}