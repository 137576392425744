html {
    width: 100%;
    height: 100%;
}

body {
    width: 100vw;
    min-height: 100%;
    color: var(--primary-color);
    background: var(--primary-bg);
    main {
        width: 100%;
    }
    
    &:not(.__scroll-manual) {
        overflow: hidden;
        height: 100vh;

        .wrap {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100vw;
        }
    }
    
    &.__scroll-manual:not(.--nav-open) {
        overflow-y: scroll;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
    }
}

.icon {
    display: inline-block;

    svg {
        height: 100%;
        width: 100%;
        
        path {
            fill: currentColor;
        }
    }
}

.hidden {
    display: none;
}

.section {
    @include z-index(2);
    position: relative;

    &.--light {
        --bg: var(--greyLightest);
        --color: var(--black);

        .button {
            --color: var(--primary-color);
            --bg: var(--primary-bg);

            &.--full:hover {
                --bg: var(--red);
            }
        }
    }
    
    &.--dark {
        --bg: var(--black);
        --color: var(--white);
    }

    &:first-child:not(.block-billboard-home):not(.block-billboard):not(.billboard-product) {
        --padding-top: calc(var(--header-height) + var(--padding-xs));
        padding-top: var(--padding-top);
    }
}

.section.--overlap {
    @include z-index(1);
    
    &:not(:last-child) {
        padding-bottom: var(--overlap);
    }

    @media (max-width: $smartphone) {
        & + .section .overlap-target {
            background: linear-gradient(transparent 50%, var(--bg) 50%);
            margin-top: calc(-1 * var(--overlap));
        }
    }
    
    @media (min-width: $smartphone) {
        margin-top: calc(var(--overlap) / -4);
        padding-top: calc(var(--overlap) / 4);
        
        & + .section {
            margin-top: calc(-1 * var(--overlap));
        }
    }        
}

[data-scroll-displace] {
    height: 140%;
}

.mask [data-scroll-displace] .media-holder,
.image[data-scroll-displace] .media-holder {
    height: 100%;
    padding-top: 0;
}

.mask [data-scroll-displace] .media-holder,
[data-scroll-displace].media-holder {
    img {
        object-fit: cover;
    }
}

.media-holder img[data-scroll-displace],
.media-holder video[data-scroll-displace] {
    height: 140%;
    object-fit: cover;
}

[data-scroll-displace][data-axis="x"] {
    height: 100%;
    width: 140%;
    
    .media-holder {
        height: 100%;

        img {
            object-fit: cover;
        }
    }
}

.line-parent {
    overflow: hidden;
}
