.block-text-product {
    --padding-v: var(--padding-l);
    --padding-left: var(--padding-xxxxxxl);
    --padding-right: var(--padding-m);

    @media (max-width: $smartphone) {
        --padding-left: var(--padding-m);
        --padding-right: var(--padding-xs);
    }

    @media (min-width: $smartphone) {
        // --font-size: 1.11111vw;
        --font-size: var(--font-size-base);
    }
}

.block-text-product {
    @include z-index(1);
    @include font-serif();
    background-color: var(--white);
    color: var(--black);
    font-size: var(--font-size);
    margin: -1px 0;
    
    .wrapper {
        padding: 0 var(--padding-right) 0 var(--padding-left);
    
        > div {
            border-left: 1px solid var(--grey);
            max-width: em(655);
            padding-top: var(--padding-v);
            padding-bottom: var(--padding-v);
            padding-left: var(--padding-xs);

            p:not(:last-child) {
                margin-bottom: var(--padding-l);
            }
        }
    }

    @media (min-width: $smartphone) {
        .wrapper > div {
            padding-left: var(--padding-m);
        }
    }
}
