@use "sass:math";

:root {
    --y-header: 0;

    --padding-xxxxxxl: #{math.div(480px, 16px) * 1rem};
    --padding-xxxxxl: #{math.div(432px, 16px) * 1rem};
    --padding-xxxxl: #{math.div(230px, 16px) * 1rem};
    --padding-xxxl: #{math.div(125px, 16px) * 1rem};
    --padding-xxl: #{math.div(105px, 16px) * 1rem};
    --padding-xl: #{math.div(90px, 16px) * 1rem};
    --padding-l: #{math.div(48px, 16px) * 1rem};
    --padding-m: #{math.div(32px, 16px) * 1rem};
    --padding-s: #{math.div(24px, 16px) * 1rem};
    --padding-xs: #{math.div(16px, 16px) * 1rem};
    --padding-xxs: #{math.div(8px, 16px) * 1rem};
    --padding-xxxs: #{math.div(4px, 16px) * 1rem};

    --header-height: #{math.div(106px, 16px) * 1rem};
    --logo-width: #{math.div(202px, 16px) * 1rem};
    
    --space-1: #{math.div(90px, 16px) * 1rem};
    --space-2: #{math.div(180px, 16px) * 1rem};
    --overlap: #{math.div(350px, 16px) * 1rem};

    --border-radius: #{math.div(36px, 16px) * 1rem};

    --content-max-width: #{math.div(1344px, 16px) * 1rem};
    
    @media (max-width: $smartphone) {
        --logo-width: #{math.div(142px, 16px) * 1rem};
        --header-height: #{math.div(94px, 16px) * 1rem};
    }

    --gap: 0;

    .--space-1 {
        --gap: var(--space-1);
    }
    
    .--space-2 {
        --gap: var(--space-2);
    }

    @media (min-width: $smartphone) {
        .--space-1,
        .--space-2 {
            width: calc(100vw - var(--gap));
        }
    }
}
