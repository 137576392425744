#Footer .form {
    --font-size-title: var(--font-size-xxxlarge);
    
    @media (max-width: $smartphone) {
        --font-size-title: var(--font-size-large);
    }

    width: 100%;

    h3 {
        @include font-sans-2(1.125);
        font-size: var(--font-size-title);
        margin-bottom: var(--padding-xxs);
    }

    p {
        display: block;
        max-width: rem(365);
        position: static;
        margin-bottom: var(--padding-m);
        transform: none;
    }

    button[type="submit"] {
        background-color: transparent;
        height: 100%;
        padding: 0;
        position: absolute;
        right: 0;
        top: 0;
        
        > .icon {
            width: rem(25);
        }
    }

    @media (min-width: $smartphone) {
        margin-top: calc(-1 * var(--padding-xxs));
        max-width: rem(430);
    }
}
