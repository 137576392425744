
.product-card {
    --font-size: var(--font-size-medium);
    --font-size-label: var(--font-size-medium);

    @media (max-width: $smartphone) {
        --font-size: var(--font-size-small);
        --font-size-label: var(--font-size-xsmall);
    }
}

.product-card {
    @include basic-a();
    color: var(--black);
    
    .label {
        @include font-sans();
        font-size: var(--font-size-label);
        // color: var(--grey);
        margin-bottom: var(--padding-xxs);
    }
    
    .name {
        @include font-serif();
        font-size: var(--font-size);
    }
    
    @media (max-width: $smartphone) {
        .body {
            padding: var(--padding-s) var(--padding-s) 0;
            margin-top: var(--padding-xxs);
        }
    }
    
    @media (min-width: $smartphone) {
        .body {
            margin-top: var(--padding-xs);
        }
    }
}
