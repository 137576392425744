.billboard-product {
    @include z-index(1);
    padding: 0 0 50vh;

    + section {
        margin-top: calc(-1 * 50vh);
    }
    
    .wrapper {
        padding: var(--padding-xxxxl) 0 0;
    }
    
    .media-holder,
    .overlay {
        height: 100vh;
    }

    .overlay {
        background-color: var(--black);
        left: 0;
        opacity: .5;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .media-holder {
        left: 0;
        padding: 0;
        position: absolute;
        top: 0;
        width: 100%;

        img {
            object-fit: cover;
        }
    }
}
