.share-product {
    --padding-v: var(--padding-l);
    --padding-h: var(--padding-m);

    @media (max-width: $smartphone) {
        --padding-left: var(--padding-m);
        --padding-right: var(--padding-xs);
    }
}

.share-product {
    background-color: var(--white);
    border-top: 1px solid var(--grey);
    color: var(--black);
    margin: -1px 0;
    padding: var(--padding-v) var(--padding-h);

    @media (max-width: $smartphone) {
        a { width: 100%; }
    }
}
