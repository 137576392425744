@mixin link {
    --color: currentColor;
    // --font-size: var(--font-size-xxsmall); Changed for AA
    --font-size: var(--font-size-base);
    
    @media (max-width: $smartphone) {
        --font-size: var(--font-size-xxxxsmall);
    }

    @include font-sans-bold();
    @include basic-a();

    align-items: center;
    cursor: pointer;
    color: var(--color);
    display: inline-flex;
    font-size: var(--font-size);
    justify-content: flex-start;
    position: relative;
    text-transform: uppercase;
    text-align: center;
    transition: .3s ease-out;
    vertical-align: top;

    * { pointer-events: none; }
    span { display: block; }

    .letter {
        height: var(--font-size);
        overflow: hidden;

        span {
            transition: transform .3s var(--ease-in-out-cubic);
        }

        @for $i from 1 through 30 {
            &:nth-child(#{$i}) span {
                transition-delay: #{$i * .02}s;
            }
        }
    }

    .icon-wrapper {
        display: flex;
        margin-right: var(--padding-xs);
        overflow: hidden;

        svg {
            path {
                fill: var(--color);
            }
        }

        .icon {
            transition: .3s ease-in-out;
        }
        
    }
    
    &.--arrow {
        .icon-wrapper {
            width: rem(25);

            svg {
                width: rem(25);
            }

            .icon {
                padding-right: rem(6);
                transform: translate3d(-100%, 0, 0);
            }
        }
    }

    &.--arrow-down {
        .icon-wrapper {
            display: block;
            height: rem(18);

            svg {
                height: rem(18);
            }

            .icon {
                padding-bottom: rem(6);
                transform: translate3d(0, -100%, 0);
            }
        }
    }

    // &.--cross {
    //     .icon {
    //         transform: rotate(0);

    //         svg {
    //             width: rem(18);
    //         }
    //     }
    // }

    // &.--full {
    //     border-color: currentColor;
    //     color: var(--color);
    //     background-color: var(--bg);

    //     svg {
    //         path {
    //             fill: var(--color);
    //         }
    //     }
    // }

    @include isCursor() {
        &:hover {
            // color: var(--color);
            // background-color: var(--bg);

            .letter span {
                transform: translateY(-100%);
            }

            svg {
                path {
                    fill: var(--color);
                }
            }
        }

        // &.--full:hover {
        //     --color: var(--white);
        //     --bg: var(--red);
        // }
        
        &.--arrow:hover,
        &.--arrow-down:hover {
            .icon {
                transform: translate3d(0, 0, 0);
            }
        }

        // &.--cross:hover {
        //     .icon {
        //         transform: rotate(90deg);
        //     }
        // }
    }
}

.link {
    @include link;
}