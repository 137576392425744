@use "sass:math";

.block-image-centered {
    --font-size: var(--font-size-xxxlarge);
    --padding-top: var(--padding-xxxl);
    --padding-bottom: calc(var(--padding-xxxxl) * 1.2);
    --padding-h: var(--padding-xl);
    --bg: var(--greyLightest);
    --image-size: #{math.div(600px, 16px) * 1rem};
    
    @media (max-width: $smartphone) {
        --font-size: var(--font-size-medium);
        --padding-top: var(--padding-l);
        --padding-bottom: var(--padding-xxl);
        --padding-h: var(--padding-xs);
        --image-size: #{math.div(275px, 16px) * 1rem};
    }
}

.block-image-centered {
    background-color: var(--bg);
    color: var(--black);
    margin: -1px 0;
    position: relative;
    
    > div {
        border-left: 1px solid var(--grey);
        padding-top: var(--padding-top);
    }

    .wrapper {
        padding: var(--padding-top) var(--padding-h) var(--padding-bottom);
        position: relative;
    
        &::before {
            border-left: 1px solid var(--grey);
            content: '';
            height: 100%;
            left: 50%;
            position: absolute;
            top: 0;
            width: 0;
        }
    }

    h2 {
        @include font-serif();
        font-size: var(--font-size);
        margin-bottom: var(--padding-l);
        text-align: center;
    }

    .mask {
        // clip-path: ellipse(38% 44% at 50% 50%);
        // clip-path: polygon(193.950% 4.670%, 3.610% 230.320%, 63.430% 411.770%, 193.950% 456.680%, 324.470% 411.770%, 384.290% 230.320%, 193.950% 4.670%);
        margin: auto;
        width: var(--image-size);

        position: relative;
        overflow: hidden;

        .icon {
            @include z-index(1);
            position: absolute;
            z-index: 10;
            width: 150%;
            height: 150%;
            display: block;
            left: 50%;
            top: 50%;
            transform: translate3d(-51%, -50%, 1px);

            path {
                fill: var(--bg);
            }
        }
    }

    @media (min-width: $smartphone) {
        padding-left: calc(var(--padding-h) + var(--padding-xl));

        > div {
            margin: auto;
            max-width: var(--content-max-width);
        }
    }
}