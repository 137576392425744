@use "sass:math";

#Sidemenu {
    --bg: var(--white);
    --color: var(--black);
    --font-size: var(--font-size-xlarge);
    --font-size-label: var(--font-size-medium);
    --font-size-index: var(--font-size-xxxlarge);
    --padding-top: var(--padding-l);
    --padding-bottom: 0;
    --padding-h: var(--padding-m);
    --side-width: var(--padding-xxl);
    
    @media (max-width: $smartphone) {
        --font-size: var(--font-size-medium);
        --font-size-label: var(--font-size-xxsmall);
        --font-size-index: var(--font-size-xxlarge);
        --side-width: #{math.div(70px, 16px) * 1rem};
        --padding-h: var(--padding-s);
    }
}

#Sidemenu {
    color: var(--color);
    height: 100vh;
    right: 0;
    position: fixed;
    top: 0;
    width: 100vw;

    @include z-index($z-index-3D);

    font-size: var(--font-size);

    &[aria-expanded='false'] {
        visibility: hidden;
        pointer-events: none;
    }

    .overlay {
        background-color: transparent;
        cursor: initial;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
    }

    & > .content {
        background-color: var(--bg);
        height: calc(var(--vh, 1vh) * 100);
        right: 0;
        position: absolute;
        top: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        width: rem(600);

        @include z-index(0);
        
        > div {
            display: flex;
            flex-direction: column;
            min-height: 100%;
            padding: var(--padding-top) var(--padding-h) var(--padding-bottom) 0;
        }

        @media (max-width: $smartphone) {
            width: 100%;
        }
    }

    .spacer {
        border-right: 1px solid var(--greyLight);
        display: block;
        width: var(--side-width);
        min-width: var(--side-width);
    }

    .top {
        @include font-sans();
        border-right: 1px solid var(--greyLight);
        font-size: var(--font-size-index);
        height: rem(255);
        width: var(--side-width);

        span {
            display: block;
            transform: rotate(-90deg) translate3D(rem(-35), rem(15), 0);
            position: relative;
        }
    }

    .list {
        .nav-item {
            @include basic-a();
            @include font-sans-2(1.2);
            
            display: flex;
            justify-content: space-between;
            overflow: hidden;
            
            &[aria-current="page"] {
                font-weight: bold;
                pointer-events: none;
            }
            
            .number {
                @include font-sans();
                font-size: var(--font-size-label);
                padding: var(--padding-s);
                padding-left: 0;

                .letter {
                    height: var(--font-size-label);
                }

                > span {
                    overflow: hidden;
                    align-items: center;
                    display: flex;
                    justify-content: flex-end;
                }
            }

            .letter {
                display: block;
                overflow: hidden;

                span {
                    display: block;
                    height: 100%;
                    transition: transform .4s var(--ease-in-out-cubic);
                }
                
                @for $i from 1 through 10 {
                    &:nth-child(#{$i}) span {
                        transition-delay: #{$i * .05}s;
                    }
                }
            }
            
            .text {
                display: flex;
                font-size: var(--font-size);
                padding: var(--padding-xs) 0;
                position: relative;

                .letter {
                    height: calc(var(--font-size) * var(--line-height-sans));
                    text-align: right;

                    span {
                        transition-delay: .15s;
                    }
                }

                .icon-wrapper {
                    margin-left: var(--padding-xs);
                    overflow: hidden;
                    display: block;
                    height: calc(var(--font-size) * var(--line-height-sans));
                    width: rem(18);

                    svg {
                        width: rem(18);
                    }

                    .icon {
                        transition: transform .4s var(--ease-in-out-cubic);
                        transition-delay: .2s;
                        padding-bottom: rem(6);
                        transform: translate3d(0, 0, 0);
                    }
                }
            }
        }

        @include isCursor() {
            .nav-item:hover {
                .letter span {
                    transform: translateY(-100%);
                }

               .icon-wrapper .icon {
                    transform: translate3d(0, -100%, 0);
                }
            }
        }
    }

    .rrss,.langs {
        display: flex;
        justify-content: space-between;
        
        ul {
            border-bottom: 1px solid var(--greyLight);
            
            flex-grow: 1;
            padding: var(--padding-xxs) 0;
        }
        
        .contact-link {
            @include font-serif();
            display: block;
            font-size: var(--font-size-label);
            text-align: right;
            padding: var(--padding-xxs) 0;
            margin: 0;

            &.is-active {
                display: none;
            }
        }
    }

    .langs {
        ul {
        border-top: 1px solid var(--greyLight);
         margin-top: rem(80);
        }
    }


    .email {
        display: flex;
        justify-content: space-between;
        flex-grow: 1;

        .contact-link {
            @include font-serif();
            display: block;
            font-size: var(--font-size-label);
            text-align: right;
            padding: var(--padding-xs) 0;
            margin: 0;
        }
    }

    @media (max-width: $smartphone) {
        & > .content {
            overflow-y: scroll;
            overflow-x: hidden;
            -webkit-overflow-scrolling: touch;
        }

        .top {
            height: rem(130);

            span {
                transform: rotate(-90deg) translate3D(rem(-30), rem(5), 0);
            }
        }

        .list {
            .nav-item {
                .number {
                    padding: var(--padding-xs);
                }
    
                .text {
                    padding: var(--padding-xxs) 0;
    
                    .letter {
                        height: auto;
    
                        span:last-child { display: none; }
                    }
                }
            }
        }

        .langs {
            ul {
                margin-top: rem(40);
            }
        }
    }
}