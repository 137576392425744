@mixin button {
    --color: var(--primary-bg);
    --bg: var(--primary-color);
    --padding-v: var(--padding-xs);
    --padding-h: var(--padding-m);
    --font-size: var(--font-size-xxsmall);

    &.--alt {
        --bg: var(--primary-bg);
        --color: var(--primary-color);
    } 
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xxs);
        --font-size: var(--font-size-xxxxsmall);

        min-height: rem(40);
    }

    @include basic-a();
    @include font-sans-bold(1.15);

    align-items: center;
    appearance: none;
    background-color: transparent;
    border: 1px solid var(--bg);
    border-radius: var(--border-radius);
    cursor: pointer;
    color: var(--bg);
    display: inline-flex;
    font-size: var(--font-size);
    justify-content: center;
    padding: var(--padding-v) var(--padding-h);
    position: relative;
    text-transform: uppercase;
    text-align: center;
    transition: .3s ease-out;
    vertical-align: top;

    * { pointer-events: none; }

    .letter {
        height: var(--font-size);
        overflow: hidden;

        span {
            display: block;
            transition: transform .3s var(--ease-in-out-cubic);
        }

        @for $i from 1 through 30 {
            &:nth-child(#{$i}) span {
                transition-delay: #{$i * .02}s;
            }
        }
    }

    .letters-wrapper {
        display: flex;
    }
    
    .icon-wrapper {
        display: flex;
        margin-left: var(--padding-xs);
        overflow: hidden;

        svg {
            path {
                fill: var(--bg);
            }
        }

        .icon {
            display: block;
            transition: .3s ease-in-out;

        }
        
    }
    
    &.--arrow {
        .icon-wrapper {
            width: rem(25);

            svg {
                width: rem(25);
            }

            .icon {
                padding-right: rem(6);
                transform: translate3d(-100%, 0, 0);
            }
        }
    }
    
    &.--arrow-down {
        .icon-wrapper {
            display: block;
            height: rem(18);

            svg {
                height: rem(18);
                width: auto;
            }

            .icon {
                padding-bottom: rem(6);
                transform: translate3d(0, -100%, 0);
            }
        }
    }

    &.--cross {
        .icon {
            transform: rotate(0);

            svg {
                width: rem(18);
            }
        }
    }

    &.--full {
        border-color: currentColor;
        color: var(--color);
        background-color: var(--bg);

        svg {
            path {
                fill: var(--color);
            }
        }
    }

    @include isCursor() {
        &:hover {
            color: var(--color);
            background-color: var(--bg);

            .letter span {
                transform: translateY(-100%);
            }

            svg {
                path {
                    fill: var(--color);
                }
            }
        }

        &.--full:hover {
            --color: var(--white);
            --bg: var(--red);
        }
        
        &.--arrow:hover,
        &.--arrow-down:hover {
            .icon {
                transform: translate3d(0, 0, 0);
            }
        }

        &.--cross:hover {
            .icon {
                transform: rotate(90deg);
            }
        }
    }
}

.button {
    @include button;
}