.block-image-product-fullscreen {
    background-color: var(--white);
    margin: -1px 0;
    
    @media (min-width: $largeScreen) {
        font-size: 1.11111vw;
        
        .wrapper {
            padding-left: var(--padding-xxxxxl);
            padding-right: var(--padding-xxl);
        }
    }
}
