@use "sass:math";

#Newsletter {
    --color: var(--white);
    --bg: var(--greyLight);
    --padding-v: var(--padding-l);
    --padding-h: var(--padding-m);
    --font-size-title: var(--font-size-large);
    --font-size: var(--font-size-xxsmall);
    --icon-size: #{math.div(40px, 16px) * 1rem};
            
    &[aria-expanded='false'] {
        visibility: hidden;
        pointer-events: none;
    }
            
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-m);
        --padding-h: var(--padding-s);
    }
}

#Newsletter {
    @include z-index($z-index-windows);
    @include font-sans();

    background-color: var(--black60);

    position: fixed;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--font-size);
    padding: var(--padding-xxs);
    
    .banner {
        background-color: var(--bg);
        padding: var(--padding-v) var(--padding-h);
        max-width: rem(390);
        width: 100%;
        position: relative;
    }

    .content {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-bottom: var(--padding-m);

        h3 {
            @include font-serif();
            font-size: var(--font-size-title);
            font-weight: normal;
            text-align: center;
            margin-bottom: var(--padding-s);
        }
        
        p {
            text-align: center;
        }

        .icon {
            margin-bottom: var(--padding-m);
            width: var(--icon-size);
        }
    }

    .controls {
        display: flex;
        justify-content: flex-end;

        button:first-child { margin-right: var(--padding-m); }
    }

    p {
        margin: 0;
        padding-bottom: 0;
    }

    .button {
        width: 100%;
    }

    .form {
        --font-size: var(--font-size-xxxsmall);
    }
}

@media (max-width: $smartphone) {
    #Newsletter {
        padding: var(--padding-xs) var(--padding-xs);
        width: 100%;

        .content {
            width: 100%;
        }
    }
}

@media (min-width: $smartphone) {
    #Newsletter {
        .button {
            margin: 0;
        }
    }
}

#Newsletter .btn {
    align-items: center;
    appearance: none;
    background-color: transparent;
    cursor: pointer;
    color: var(--bg);
    display: inline-flex;
    justify-content: center;
    padding: 0;
    position: relative;
    transform: rotate(45deg);

    position: absolute;
    right: var(--padding-xs);
    top: var(--padding-xs);
    transform: rotate(45deg);
    width: rem(20);
    height: rem(20);

    .icon svg path {
        fill: var(--white);
    }
}
