.block-highlight {
    --font-size: var(--font-size-xxxxlarge);
    --padding-top: var(--padding-xxxxl);
    --padding-bottom: var(--padding-xxxl);
    --padding-h: var(--padding-l);
    
    @media (max-width: $smartphone) {
        --font-size: var(--font-size-xxlarge);
        --padding-h: var(--padding-xs);
        --padding-top: var(--padding-xxxl);
        --padding-bottom: var(--padding-xxl);
    }
}

.block-highlight {
    @include z-index(3);
    align-items: center;
    display: flex;
    background-color: var(--black);
    flex-direction: column;
    justify-content: center;
    text-align: center;
    position: relative;
    
    h2 {
        @include font-sans-2();
        font-size: var(--font-size);
    }

    .image {
        height: auto;
        position: absolute;
        width: rem(200);

        &:nth-child(2) {
            left: 20%;
            top: 0%;
        }

        &:nth-child(3) {
            left: 40%;
            top: -5%;
        }
        
        &:nth-child(4) {
            left: 65%;
            top: 8%;
        }
        
        @media (min-width: $tabletLandscape) {
            &:nth-child(2) {
                top: 10%;
            }
            
            &:nth-child(3) {
                top: 50%;
            }
        }
    }

    > .text {
        margin: auto;
        max-width: rem(1245);
        padding: var(--padding-top) var(--padding-h) var(--padding-bottom);
    }

    @media (max-width: $smartphone) {
        .image {
            height: auto;
            position: absolute;
            width: rem(100);
        }
    }
}
