.block-image-product {
    --padding-left: var(--padding-xxxxxxl);

    @media (max-width: $smartphone) {
        --padding-left: var(--padding-m);
    }
}

.block-image-product {
    @include z-index(1);
    margin: -1px 0;
    
    .wrapper {
        background-color: var(--white);
        padding-left: var(--padding-left);
    }
}
