.widget-contact {
    --font-size-title: var(--font-size-xxxlarge);
    --padding-v: var(--padding-xl);
    --padding-h: var(--padding-l);
    
    @media (max-width: $smartphone) {
        --font-size-title: var(--font-size-large);
        --padding-v: var(--padding-l);
        --padding-h: var(--padding-s);
    }
}

.widget-contact {
    @include font-sans();
    color: var(--color);
    background-color: transparent;
    padding: var(--padding-v) var(--padding-h);

    h2 {
        @include font-sans-2();
        font-size: var(--font-size-title);
        margin: 0 0 var(--padding-s);
    }

    .--dark + &.--dark {
        border-top: 1px solid var(--greyLight);
    }

    @media (max-width: $smartphone) {
        > div {
            > div:first-child {
                margin-bottom: var(--padding-l);
            }
        }
    }

    @media (min-width: $smartphone) {
        > div {
            align-items: flex-start;
            display: flex;
            justify-content: space-between;
            gap: var(--padding-s);
            margin: auto;
            max-width: var(--content-max-width);
    
            > * {
                max-width: rem(550);
            }
        }

        &.--light > div {
            max-width: var(--content-max-width);
        }
    }
}
