@use "sass:math";

.next-product {
    --font-size: var(--font-size-xxxlarge);
    --font-size-label: var(--font-size-xlarge);
    --font-size-title: var(--font-size-base);
    --padding-v: var(--padding-xxxl);
    --padding-h: var(--padding-xl);
    --bg: var(--greyLighter);
    
    @media (max-width: $smartphone) {
        --font-size: var(--font-size-xlarge);
        --font-size-label: var(--font-size-xsmall);
        --font-size-title: var(--font-size-xxxxsmall);
        --padding-v: var(--padding-l);
        --padding-h: var(--padding-s);
    }
}

.next-product {
    color: var(--black);
    overflow: hidden;
    position: relative;
    
    .text-content {
        .label {
            font-size: var(--font-size-label);
        }
        
        h2 {
            font-size: var(--font-size-title);
        }
        
        .text {
            font-size: var(--font-size);
        }
    }
    
    .content {
        background-color: var(--bg);
        margin: -2px 0;
        padding: var(--padding-v) var(--padding-h);
        position: relative;
    }

    .text-content > div {
        padding: 0;
    }
    
    @media (min-width: $smartphone) {
        &.--right .wrapper {
            flex-direction: row-reverse;
        }

        .image {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
        
        .wrapper {
            display: flex;
            gap: var(--padding-xl);

            > div {
                flex: 0 0 calc(50% - var(--padding-xl) / 2);
            }
            
            .content {
                align-items: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
    }
}