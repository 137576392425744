#Preloader {
    --font-size: var(--font-size-s);
    --color: var(--white);
    --bg: var(--black);

    // @media (max-width: $smartphone) {
    //     --font-size: 4vw;
    // }
}

#Preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    @include z-index($z-index-loader);
    color: var(--color);
    background-color: var(--bg);
    
    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: center;

    .icon {
        width: var(--logo-width);
    }

    .logo {
        position: relative;
    }

    .overlay {
        background: linear-gradient(120deg, transparent 0%, var(--bg) 45%);
        height: 100%;
        right: 0;
        position: absolute;
        top: 0;
        width: 180%;
        transform: translateX(0%);
    }
}
