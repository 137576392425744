.block-billboard {
    --font-size: var(--font-size-xlarge);
    --font-size-title: var(--font-size-xxxxlarge);
    --padding-top: var(--header-height);
    --padding-bottom: var(--padding-xl);
    --padding-h: var(--padding-xl);
    
    @media (max-width: $smartphone) {
        --font-size: var(--font-size-small);
        --font-size-title: var(--font-size-xlarge);
        --padding-top: var(--padding-xxxl);
        --padding-bottom: var(--padding-l);
        --padding-h: var(--padding-xs);
    }
}

.block-billboard {
    @include font-serif();
    
    > div {
        align-items: center;
        color: var(--white);
        display: flex;
        flex-direction: column;
        padding: var(--padding-top) var(--padding-h) var(--padding-bottom);
        justify-content: center;
        text-align: center;
        font-size: var(--font-size);
    }
    
    .icon {
        height: rem(52);
        width: rem(270);
    }

    .content {
        @include z-index($z-index-wrap);
        position: relative;
    }
    
    h1 {
        @include font-sans-2(1.15);
        margin-bottom: var(--padding-l);
        font-size: var(--font-size-title);
        
        &:not(:first-child) {
            margin-top: var(--padding-m);
        }
    }

    .overlay {
        @include z-index($z-index-3D);
        background-color: var(--black);
        height: 100%;
        left: 0;
        // opacity: .25;
        opacity: .4;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .media-holder {
        @include z-index($z-index-bg);
        height: 100%;
        left: 0;
        padding: 0;
        position: absolute;
        top: 0;
        width: 100%;

        img,
        video {
            object-fit: cover;
        }
    }

    @media (min-width: $smartphone) {
        > div {
            min-height: 100vh;
        }

        .media-holder,
        .overlay {
            height: 100vh;
        }

        & + .section {
            margin-top: calc(-1 * var(--padding-xl));
        }

        .content {
            max-width: rem(1180);
        }

        p {
            margin: 0 auto;
            max-width: rem(885);
        }
    }
}