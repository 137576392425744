@use "sass:math";

.block-acordions {
    --font-size-title: var(--font-size-xlarge);
    --font-size-body: var(--font-size-base);
    --padding-v: 0;
    --padding-h: var(--padding-l);
    --bg: var(--greyLightest);
        
    @media (max-width: $smartphone) {
        --font-size-title: var(--font-size-small);
        --font-size-body: var(--font-size-xsmall);
        --padding-v: 0;
        --padding-h: var(--padding-xs);
    }
}

.block-acordions {
    background-color: var(--bg);
    margin: -1px 0;
    padding: var(--padding-v) var(--padding-h);

    @include font-sans();

    &__ul {
        list-style: none;
        margin: 0 auto;
        padding-bottom: var(--padding-xxxs);
    
        > li {
            border-top: 1px solid var(--grey);

            &:last-child { border-bottom: 1px solid var(--grey); }
        }
    
        //ACORDEON
        article {
            --duration: 0.4s;
            --ease: var(--ease-in-out-quad);
            color: var(--black);
            
            > [data-acordion-toggle] {
                --rot: 0deg;
                --strokeCross: #{math.div(2px, 16px) * 1rem};
                
                @include font-serif(2);
                @include basic-a();
                background-color: transparent;
                position: relative;
                width: 100%;
                padding: var(--padding-xs) 0;
                font-size: var(--font-size-title);
                text-align: left;
        
                > span {
                    --rotAfter: 0;
                    --rotBefore: 0;
    
                    position: absolute;
                    right: 0.1em;
                    top: 50%;
                    width: var(--padding-s);
                    height: var(--padding-s);
        
                    transform-origin: 50% 50%;
                    transform: translate3d(0, -50%, 0) rotate(var(--rot));
        
                    transition: transform 0.4s var(--ease);
        
                    &::after,
                    &::before {
                        background: currentColor;
                        content: '';
                        position: absolute;
                        transition: transform .3s ease-in-out .1s;
                    }
                    
                    &::after {
                        height: 100%;
                        top: 0;
                        left: 50%;
                        width: var(--strokeCross);
                        transform: translateX(-50%) rotate(var(--rotAfter));
                    }
                    
                    &::before {
                        top: 50%;
                        left: 0;
                        height: var(--strokeCross);
                        transform: translateY(-50%) rotate(var(--rotBefore));
                        width: 100%;
                    }
                }
            }

            > [data-acordion-group] {
                font-size: var(--font-size-body);
                padding: var(--padding-xxs) 0 var(--padding-m);

                li {
                    position: relative;
                    
                    &::before {
                        content: '';
                        margin-right: var(--padding-xs);
                    }

                    &::after {
                        background-color: var(--black);
                        height: rem(4);
                        content: '';
                        left: 0;
                        position: absolute;
                        top: calc(var(--font-size-body) * var(--line-height-sans) / 2);
                        transform: translateY(-50%);
                        width: rem(4)
                    }
                }

                li:not(:last-child) {
                    margin-bottom: var(--padding-xs);
                }

                p:not(:last-child) {
                    margin-bottom: var(--padding-s);
                }
            }
        }
    
        article[aria-expanded='false'] {
            visibility: visible;
            pointer-events: initial;
        }
        
        article[aria-expanded='true'] > [data-acordion-toggle] {
            --rot: 135deg;

            > span {
                --rotAfter: 135deg;
                --rotBefore: 45deg;
            }
        }
    }
   
    @media (min-width: $smartphone) {
        article > [data-acordion-group] {
            > div:first-child { padding-bottom: var(--padding-m); }
        }
    }

    @media (min-width: $smartphone) {
        article {
            > [data-acordion-toggle] {
                padding: var(--padding-s) 0;
            }

            > [data-acordion-group] {
                display: flex;
                padding: var(--padding-xs) 0 var(--padding-m);
                max-width: var(--content-max-width);
                margin: auto;

                > div { flex: 0 0 50%; }
                > div:first-child { padding-right: var(--padding-l); }
                > div:last-child { padding-left: var(--padding-l); }
            }
        }
    }
}
