.block-product-list {
    --font-size-top-1: var(--font-size-xlarge);
    --font-size-top-2: var(--font-size-base);
    --padding-top-v: var(--padding-xxl);
    --padding-top-h: var(--padding-xxl);
    --padding-v: var(--padding-xl);
    --padding-h: var(--padding-l);
    // --padding-right: var(--padding-l);
    --bg: var(--greyDark);
    
    @media (max-width: $smartphone) {
        --font-size-top-1: var(--font-size-xsmall);
        --font-size-top-2: var(--font-size-xxsmall);
        --padding-top-v: var(--padding-l);
        --padding-top-h: var(--padding-s);
        --padding-v: var(--padding-l);
        --padding-h: 0;
    }
}

.block-product-list {
    .icon {
        pointer-events: none
    }

    .bottom {
        @include z-index(2);
        background-color: var(--bg);
        color: var(--white);
        overflow: hidden;
        padding: var(--padding-top-v) var(--padding-top-h);
        position: relative;

        h2 {
           @include font-serif(1.2);
           font-size: var(--font-size-top-1); 
           text-align: center;
           margin-bottom: var(--padding-m);
        }
        
        > div:last-child {
           @include font-sans();
           font-size: var(--font-size-top-2); 
        }
        
        p:not(:last-child) {
            margin-bottom: var(--padding-s)
        }

        > .icon {
            @include z-index(0);
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);
            width: 100%;

            svg {
                height: auto;
                width: 100%;

                path {
                    fill: transparent;
                }
            }
        }
    }

    .wrapper {
        @include z-index(1);
        background-color: var(--white);
        position: relative;
    }

    .content {
        padding: var(--padding-v) var(--padding-h);

        ul li {
            margin-bottom: var(--padding-l);
        }
    }

    .filters {
        margin-bottom: var(--padding-m);

        > div {
            display: flex;
        }

        .button {
            margin-right: var(--padding-xs);

            &.--active {
                color: var(--color);
                background-color: var(--bg);
                pointer-events: none;
            }
        }
    }

    @media (max-width: $smartphone) {
        .filters {
            padding-right: var(--padding-h);
            overflow-y: hidden;

            > div {
                display: flex;
                scroll-snap-type: x mandatory;	
                -webkit-overflow-scrolling: touch;
                overflow-x: scroll;
                padding-right: var(--padding-xs);
            }
            
            .button {
                flex-shrink: 0;
                margin-right: var(--padding-xxs);
                
                &:first-child {
                    margin-left: var(--padding-xs);
                }
            }
        }

        .bottom {
            .img {
                display: none;
            }

            p {
                text-align: center;
            }
        }
    }

    @media (min-width: $smartphone) {
        .bottom {
            h2 {
                margin-left: auto;
                margin-right: auto;
                max-width: rem(860);
            }

            > div {
                align-items: center;
                display: flex;
                gap: var(--padding-xl);

                * {
                    flex: 0 0 calc(50% - var(--padding-xl) / 2);
                }
            }

            .img {
                padding: var(--padding-m);
            }
        }

        .filters {
            border-bottom: 1px solid var(--grey);
            padding-bottom: var(--padding-m);
        }

        .content {
            ul {
                display: flex;
                flex-wrap: wrap;
                gap: var(--padding-s);
            }

            li {
                margin-bottom: var(--padding-xl);
                width: calc(100% / 3 - 2 * var(--padding-s) / 3);
            }
        }
    }
}
