@use "sass:math";

#Whatsapp {
    --bg: var(--red);
    --padding: var(--padding-xs);
    --icon-size: #{math.div(57px, 16px) * 1rem};
    
    @media (max-width: $smartphone) {
        // --padding-v: var(--padding-xxs);
        // --icon-size: #{math.div(47px, 16px) * 1rem};

        display: none;
    }
}

#Whatsapp {
    @include z-index($z-index-whatsapp);

    background-color: var(--bg);
    border-radius: 50%;
    bottom: var(--space-1);
    padding: var(--padding);
    position: fixed;
    right: var(--space-1);
    transform: translate3D(50%, 50%, 0);

    .icon {
        height: var(--icon-size);
        width: var(--icon-size);

        svg path {
            fill: var(--white);
        }
    }
}
